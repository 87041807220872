import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/vercel/path0/node_modules/gatsby-theme-carbon/src/templates/Default.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`Warmup: Reverse Hyperextensions 10-10-10-10`}</p>
    <p>{`Planks                                 :45-:45-:45-:45`}</p>
    <p>{`Deadlifts 2-2-2-2-2 to a 2RM`}</p>
    <p>{`then,`}</p>
    <p>{`10:00 AMRAP of:`}</p>
    <p>{`10-Deadlifts (155/105)`}</p>
    <p>{`10-Box Jumps (24/20″)`}</p>
    <p>{`10-Pushups`}</p>
    <p><strong parentName="p"><em parentName="strong">{`*`}{`This Saturday is our free class at 9:00 & 10:00am so bring a
friend!`}</em></strong></p>
    <p><strong parentName="p"><em parentName="strong">{`*`}{`Our Annual free Thanksgiving morning WOD will be at 9:00am next
Thursday.  All other classes and open gym will be cancelled for the day
as well as the 6:00am class on Friday.  Classes will be back to normal
starting at 10:30am on Friday.`}</em></strong></p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      